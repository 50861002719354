<template>
  <div class="main-header">
    <h1>Hi I’m <br>Vishnukumar</h1>
    <h2>Drupal Developer</h2>
    <p>Experienced Software Engineer with a demonstrated history of working in the web technology and services industry for about 5 years. Skilled in Drupal 7/8/9, Laravel, VueJS, SQL, PHP, WordPress and Databases.</p>
    <SkillsComponent />
    <a class="link mail" href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;%68%65%6C%6C%6F%76%69%73%68%6E%75%6B%70%76%40%67%6D%61%69%6C%2E%63%6F%6D">hellovishnukpv[@]gmail[.]com</a>
    <a class="link" href="https://www.linkedin.com/in/vishnukumarpv">linkedin/vishnukumarpv</a>
  </div>
</template>

<script>

import SkillsComponent from '../components/SkillsComponent.vue'

export default {
  name: 'HeaderComponent',
  components:{
    SkillsComponent,
  }
}
</script>


<style scoped>

.main-header h1 {
    font-size: 4em;
    font-weight: 600;
}

.main-header {
    padding: 3% 0 0 10%;
    z-index: 9999;
}

.main-header h2 {
    padding: 10px 0;
    font-weight: 500;
    color: #2196f3;
}
.main-header p {
    font-weight: 300;
    padding: 0 2% 0 0;
    line-height: 1.5;
    color: #00526B;
}
a.link {
    display: block;
    text-decoration: none;
    color: #3996f3;
    font-weight: 300;
    margin-top:10px;
}

a.link.mail {
    margin-top: 10%;
}

.main-header {
    position: relative;
}
.main-header .svg-drupal {
    position: absolute;
    right: 0;
    height: 200px;
    width: 200px;
    top: 0;
    z-index: 1;
}
@media screen and (max-width: 600px) {

    .main-header h1{
        font-size:3em;
    }
}
</style>
