<template>
  <div class="main">
    <div class="col1">
        <HeaderComponent />
        <div class="svg-drupal">
            <DrupalLogo />
        </div>
    </div>
    <div class="col2 img-wrp">
    </div>
  </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue'
import DrupalLogo from '../components/DrupalLogo.vue'
export default {
  name: 'HomeView',
  components:{
      HeaderComponent,
      DrupalLogo
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main {
    display: flex;
    background:#fff;
}
.col1 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60%;
    height: 100%;
    min-height: 100vh;
}

.col2 {
    width: 40%;
    background: #000;
    height: 20px;
    min-height: 100vh;
}

.img-wrp img {
    width: 100%;
    /* height: 100%; */
    position: relative;
}

.img-wrp::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: #137fd8b0;
    left: 0;
}

.img-wrp {
    position: relative;
    display: block;
    background: url("../assets/vishnukumar-233.jpg") no-repeat;
    background-size: cover;
}

.svg-drupal svg {
    width: 100%;
}

.svg-drupal {
    width: 270px;
    position: absolute;
    top: 20px;
    right: 28%;
    z-index: 999;
}

@media screen and (max-width: 600px) {
  .img-wrp {
    display:none;
  }
  .col1 {
    width: 100%;
    }
}

</style>
