<template>
  <div class="skills-wrap">
    <h4>Skills</h4>
    <div class="list">
      <span>Drupal 7/8/9</span>
      <span>PHP</span>
      <span>MySQL</span>
      <span>HTML5</span>
      <span>CSS/SCSS</span>
      <span>Javascript</span>
      <span>Vue</span>
      <span>Laravel</span>
      <span>Wordpress</span>
      <span>Git</span>
      <span>Linux</span>
      <span>AWS - EC2</span>
      <span>MongoDB</span>
      <span>Docker</span>
      <span>Lando</span>
      <span>Figma/Adobe XD</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SkillsComponent',
}
</script>

<style scoped>
.skills-wrap h4 {
    font-weight: 500;
    color: #1d6dbd;
    font-size: 20px;
    margin-top: 4%;
}

.skills-wrap .list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
    font-weight: 300;
    color: #3a97f4;
}

.skills-wrap .list span {
    margin-right: 10px;
    border: solid 1px #2196f3;
    border-radius: 5px;
    color: #2c75c4;
    padding: 6px 7px;
    font-size: 12px;
    margin-top:12px
}

 
</style>
